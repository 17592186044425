<template>
  <div >
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="body">
        <div class="head">
            <van-image class="img"
                round
                width="2rem"
                height="2rem"
                :src="url"
            />
            <p class="name">{{ name }}</p>
            <p class="fans"><span>关注：{{ authormessage.followTotals }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>粉丝：{{ authormessage.fansTotals }}</span></p>
            <div class="button" v-if="userid!==id">
                <van-button @click="follow(1)" v-if="!authormessage.isMyFollow" icon="plus" type="danger">关注</van-button>
                <van-button @click="follow(0)" v-else icon="success" type="primary">已关注</van-button>
            </div>
        </div>
        <div>
            <van-tabs v-model="active" swipeable>
                <van-tab :title="'排列五('+(five.length)+')'"><v-content :list="five"></v-content></van-tab>
                <van-tab :title="'七星彩('+(seven.length)+')'"><v-content :list="seven"></v-content></van-tab>
               
            </van-tabs>

        </div>
    </div>
    
  </div>
</template>

<script>
import vHeader from '@/components/header.vue';
import vContent from '@/components/content.vue';
export default {
    components:{
        vHeader,
        vContent,
    },
    data(){
        return{
            userid:this.$store.state.common.loginInfo.userId,
            active:0,
            test:6,
            title:this.$route.meta.title,
            id:this.$route.query.postid,
            url:this.$route.query.posturl,
            name:this.$route.query.postname,
            authormessage:'',
            // followTotals:0,
            // fansTotals:0,
            // isMyFollow:false,
            pagination: {
                pageNo: 1,
                pageSize: 20000,
            },
            five:[],
            seven:[]

        }
    },
    methods:{
         async getauthorinfo(){
            let data={
                master_user_id:this.id,
            }
            const res = await this.$HTTP.get(this, this.$API['API_ONE_FORUM_ANON_FOLLOW_SITUATION'], data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                if (res.statusCode === 200) {
                    // console.log('作者信息',res.data)
                    this.authormessage=res.data
                    // this.$set(this.authormessage,'followTotals',res.data.followTotals)
                    // this.$set(this.authormessage,'fansTotals',res.data.fansTotals)
                    // this.$set(this.authormessage,'isMyFollow',res.data.isMyFollow)
                    // this.$forceUpdate();
                    // this.followTotals=res.data.followTotals
                    // this.fansTotals=res.data.fansTotals
                    // this.isMyFollow=res.data.isMyFollow

                }
        },
        async follow(n){
            let data={
                "kindId": 1,
                "status": n, //1为关注 0为取消关注
                "systemId": 100001,
                "targetUserId": this.id
            }
            const res = await this.$HTTP.put(this, 'API_ONE_FORUM_FANS_FOLLOW', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                if (res.statusCode === 200) {
                    // console.log('关注信息',res.data)
                    this.getauthorinfo()
                    // window.location.reload()

                }
        },
        async getfivelist(){
            let data={
                lotteryKind: 5,
                // 0头条 1预测 2鸡汤
                forumTabIndex:1,
                // 第几期 比如:2591 表示 2591期
                periodTextNum: '',
                // pageNo: this.pagination.pageNo,
                // pageSize: this.pagination.pageSize,
                postAuthorUserId: this.id
            }
            const res = await this.$HTTP.post(this, 'API_USERCENTER_FORUM_ANON_LISTS', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            // if (res.statusCode === 200) {
            //     console.log('排列五列表',res.data)
            //     // console.log('排列五列表',res)
            //     // this.five=res.data

            // }
            if (res.statusCode === 200) {
                for (let item of res.data) {
                    if(item.roi!==null){
                        item.roi=parseFloat(item.roi).toFixed(2)
                    }
                    try {
                        item.postAuthor = item.postAuthor ? JSON.parse(item.postAuthor) : {}
                    } catch (e) {
                        item.postAuthor = {}
                    }
                    try {
                        item.decode = item.contents ? this.htmlDecodeByRegExp(item.contents) : ''
                    } catch (e) {
                        item.decode = ''
                    }
                    if (item.imagesUrl) {
                        try {
                        item.imagesUrlArray = JSON.parse(item.imagesUrl)
                        } catch (e) {
                        item.imagesUrlArray = []
                        }
                    } else {
                        item.imagesUrlArray = []
                    }
                }
                this.url=res.data[0].postAuthor.avatar_url
                this.name=res.data[0].postAuthor.author
            // console.log('接口返回来的数据',res)

            let arr=res.data.map(item=>{
                const qian=item.lotteryContent!==null?item.lotteryContent.substr(0,1):'', bai=item.lotteryContent!==null?item.lotteryContent.substr(1,1):'',shi=item.lotteryContent!==null?item.lotteryContent.substr(2,1):'',ge=item.lotteryContent!==null?item.lotteryContent.substr(3,1):'',winning_number_twoshow=[],winning_number_threeshow=[]
                let qiangesum='',baishisum='',winning_number=''

                if(item.lotteryContent!==null){
                baishisum=(parseInt(bai)+parseInt(shi))%10+''
                qiangesum=(parseInt(qian)+parseInt(ge))%10+''
                winning_number=qian+bai+shi+ge
                let first
                let second
                let three
                let temp
                let temp2
                for(let i=0;i<winning_number.length;i++){
                    first=winning_number[i]
                    temp=winning_number.replace(winning_number[i],'')
                    for(let j=0;j<temp.length;j++){
                    second=temp[j]
                    winning_number_twoshow.push(first+second)
                    temp2=temp.replace(temp[j],'')
                    for(let k=0;k<temp2.length;k++){
                        three=temp2[k]
                        winning_number_threeshow.push(first+second+three)
                    }
                    }
                }
                
                }else{
                baishisum=''
                qiangesum=''
                winning_number=''
                }
                
                let itemData={
                ...item,
                qian,
                bai,
                shi,
                ge,
                qiangesum,
                baishisum,
                winning_number,
                winning_number_twoshow,
                winning_number_threeshow
                }
                return itemData
            })
            this.five=arr

            // console.log('test修改返回的数据排列五',this.five)
            
            }
        },
        async getsevenlist(){
            let data={
                lotteryKind: 7,
                // 0头条 1预测 2鸡汤
                forumTabIndex: 1,
                // 第几期 比如:2591 表示 2591期
                periodTextNum: '',
                // pageNo: this.pagination.pageNo,
                // pageSize: this.pagination.pageSize
                postAuthorUserId: this.id
            }
            const res = await this.$HTTP.post(this, 'API_USERCENTER_FORUM_ANON_LISTS', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                // if (res.statusCode === 200) {
                //     console.log('七星彩列表',res.data)
                //     // this.seven=res.data||[]

                // }
                if (res.statusCode === 200) {
                for (let item of res.data) {
                    if(item.roi!==null){
                        item.roi=parseFloat(item.roi).toFixed(2)
                    }
                    try {
                        item.postAuthor = item.postAuthor ? JSON.parse(item.postAuthor) : {}
                    } catch (e) {
                        item.postAuthor = {}
                    }
                    try {
                        item.decode = item.contents ? this.htmlDecodeByRegExp(item.contents) : ''
                    } catch (e) {
                        item.decode = ''
                    }
                    if (item.imagesUrl) {
                        try {
                        item.imagesUrlArray = JSON.parse(item.imagesUrl)
                        } catch (e) {
                        item.imagesUrlArray = []
                        }
                    } else {
                        item.imagesUrlArray = []
                    }
                    }
            // console.log('接口返回来的数据',res)

            let arr=res.data.map(item=>{
                const qian=item.lotteryContent!==null?item.lotteryContent.substr(0,1):'', bai=item.lotteryContent!==null?item.lotteryContent.substr(1,1):'',shi=item.lotteryContent!==null?item.lotteryContent.substr(2,1):'',ge=item.lotteryContent!==null?item.lotteryContent.substr(3,1):'',winning_number_twoshow=[],winning_number_threeshow=[]
                let qiangesum='',baishisum='',winning_number=''

                if(item.lotteryContent!==null){
                baishisum=(parseInt(bai)+parseInt(shi))%10+''
                qiangesum=(parseInt(qian)+parseInt(ge))%10+''
                winning_number=qian+bai+shi+ge
                let first
                let second
                let three
                let temp
                let temp2
                for(let i=0;i<winning_number.length;i++){
                    first=winning_number[i]
                    temp=winning_number.replace(winning_number[i],'')
                    for(let j=0;j<temp.length;j++){
                    second=temp[j]
                    winning_number_twoshow.push(first+second)
                    temp2=temp.replace(temp[j],'')
                    for(let k=0;k<temp2.length;k++){
                        three=temp2[k]
                        winning_number_threeshow.push(first+second+three)
                    }
                    }
                }
                
                }else{
                baishisum=''
                qiangesum=''
                winning_number=''
                }
                
                let itemData={
                ...item,
                qian,
                bai,
                shi,
                ge,
                qiangesum,
                baishisum,
                winning_number,
                winning_number_twoshow,
                winning_number_threeshow
                }
                return itemData
            })
            this.seven=arr

            // console.log('test修改返回的数据七星彩',this.seven)
            
            }
        }
    },
    mounted(){
        if (this.$store.state.common.loginInfo.access_token===null||this.$store.state.common.loginInfo.access_token==='') {
            this.$router.replace('/login')
        }
        this.getauthorinfo()
        this.getfivelist()
        this.getsevenlist()
        
    }
}
</script>

<style lang="less" scoped>

.head{
    
    margin: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.5rem;
    width: 90%;
    height: 4rem;
    background-color: white;

    background-color: white;
    .img{
        position: relative;
        top: -0.5rem;
        left: 0.5rem;
        // border: 2px solid red;
    }
    .name{
        display: inline-block;
        font-size: 0.5rem;
        font-weight: 800;
        margin-left: 1rem;
        position: relative;
        top: -0.8rem;
        left: 0.2rem;
    }
    .fans{
        position: relative;
        top: -0.9rem;
        left: 0.5rem
    }
    .button{
        position: relative;
        top: -2.3rem;
        right: -6rem;
    }
}
.body{
    overflow-x:hidden;
}
</style>